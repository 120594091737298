import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { privateRequest } from "services/privateRequest";

export const fetchSupport = createAsyncThunk('support/getOptions', async() => {
    try {
        const response = await privateRequest.get("/support");
        console.log(response.data);
      } catch (error) {
        throw error.response.data;
      }
})

export const sendSupport = createAsyncThunk('support/sendSupport', async(params) => {
  try {
      const response = await privateRequest.post("api/support", params);
      // console.log(response.data);
      toast.success(response.data.message || "Issue reported")
    } catch (error) {
      throw error.response.data;
    }
})

const initialState = {
    
  };

  const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        builder
        .addCase(fetchSupport.pending, (state, action) => {

        })
        .addCase(fetchSupport.fulfilled, (state, action) => {
                
        })
        .addCase(fetchSupport.rejected, (state, action) => {
           
        })
    }
    })

export default supportSlice.reducers