import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  CREATE_PROJECT,
  DEFAULT_ICONS,
  PROJECT_LIST,
} from "services/endPoints";
import { privateRequest } from "services/privateRequest";
import { PATH } from "utils/pagePath";
import { getProjectId } from "utils/utils";

const initialState = {
  projectsList: [],
  loading: false, //Button Loader
  loader: false, //Table Loader
  pageNo: 1,
  pageSize: 10,
  total: 0,
  params: {},
  projectData: {},
  defaultIcons: [],
};

export const getProjectsList = createAsyncThunk(
  "entity/getProjectsList",
  async (params) => {
    const res = await privateRequest.get(PROJECT_LIST, { params });
    return {
      data: res?.data?.data?.data || [],
      total: res?.data?.data?.count?.total || 0
    };
  }
);

export const getDefaultIcons = createAsyncThunk(
  "entity/getDefaultIcons",
  async () => {
    const res = await privateRequest.get(DEFAULT_ICONS);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const getProjectData = createAsyncThunk(
  "entity/getProjectData",
  async (id) => {
    const res = await privateRequest.get(`${PROJECT_LIST}/${id}`);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const createProject = createAsyncThunk(
  "entity/createProject",
  async (params) => {
    const formData = new FormData()
    formData.append("name",params.name)
    formData.append("key",params.key)
    formData.append("description",params.description)


    const res = await privateRequest.post(CREATE_PROJECT, formData);
    if (res) {
      toast.success(res?.data?.message);
    }

    return {
      data: res?.data?.data || [],
      totalEntity: res?.data?.count || 0,
      message: res?.data?.message || "",
    };
  }
);

export const deleteProject = createAsyncThunk(
  "entity/deleteProject",
  async (params, { dispatch }) => {
    const res = await privateRequest.delete(`${CREATE_PROJECT}/${params?.id}`);
    await dispatch(getProjectsList());
    toast.success(res?.data?.message);
    // return {
    //   data: res?.data?.data || [],
    //   totalEntity: res?.data?.count || 0,
    //   message: res?.data?.message || "",
    // };
  }
);

export const updateProject = createAsyncThunk(
  "entity/updateProject",
  async ({ formData, navigate, reset }) => {
    try {
      const res = await privateRequest.put(
        `${PROJECT_LIST}/${getProjectId()}`,
        formData
      );
      if (res) {
        toast.success(res?.data?.message);
        navigate(PATH.PROJECTS);
        reset();
      }
    } catch (error) {
      console.log("Request canceled", error?.message);
      toast.error(error?.response?.data?.message);
    }
  }
);

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    },
    clearProjectData: (state) => {
      state.projectData = initialState.projectData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsList.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getProjectsList.fulfilled, (state, action) => {
        state.projectsList = action.payload.data;
        state.total = action.payload.total;
        state.loader = false;
      })
      .addCase(getProjectsList.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getDefaultIcons.fulfilled, (state, action) => {
        state.defaultIcons = action.payload.data;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getProjectData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProjectData.fulfilled, (state, action) => {
        state.projectData = action.payload.data;
        state.loading = false;
      })
      .addCase(getProjectData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default projectSlice.reducer;
export const {
  setLoading,
  setLoader,
  setPageNo,
  setPageSize,
  setParams,
  clearProjectData,
} = projectSlice.actions;
