//auth
export const LOGIN = "/api/auth/login";
export const REGISTER = "/api/auth/register";
export const REFRESH_TOKEN = "/api/auth/refresh-token";
export const FORGOT_PASSWORD = "/api/auth/forgot-password";
export const OTP_VERIFICATION = "/api/auth/otp-verify";
export const CONFIRM_PASSWORD = "/api/auth/reset-password";
export const INVITATION = "/api/auth/invitation";
export const CONFIRM_INVITATION = "/api/auth/confirm-invitation";
export const SET_PASSWORD = "/api/auth/join";

//Projects
export const PROJECT_LIST = "/api/projects";
export const CREATE_PROJECT = "/api/projects";
export const PROJECT_KEY = "/api/projects/key";
export const PROJECT_NAME = "/api/projects/validate";

//Users
export const USERS = "/api/users";

//Assign Members
export const ASSIGN_PROJECT_MEMBERS = "/api/user-projects/assign";
export const REMOVE_PROJECT_MEMBERS = "/api/user-projects/remove-user";
export const UPDATE_PROJECT_MEMBER_ROLE = "/api/user-projects";

//Issues
export const CREATE_ISSUES = "/api/issues";
export const ISSUE_DATA = "/api/issues";
export const EDIT_ISSUE = "/api/issues";
export const DELETE_ISSUE = "/api/issues";
export const EXPORT_ISSUE = "/api/issues";
export const GET_PROJECT_EPICS = "/api/issues/epic";
export const GET_ALL_STAGE_ISSUES = "/api/issues/sprint-started";

//Active sprints
export const ACTIVE_SPRINT_STAGES = "/api/stages";
export const GET_ALL_ACTIVE_SPRINT_STAGES = "/api/stages/project";
export const DRAG_ISSUES_STAGES = "/api/issues/stages";
export const ISSUES_SEQUENCE = "/api/issues/sequence";
export const PROJECT_FILTER_DETAILS = "/api/issues/filter-details";

//profile
export const UPLOAD_PROFILE = "/api/users/upload-profile";
export const USER_PROFILE = "/api/users";

//Sprints
export const SPRINT_LIST = "/api/sprints/project";
export const CREATE_SPRINT = "/api/sprints";
export const SPRINT_DATA = "/api/sprints";
export const DELETE_SPRINT_DATA = "/api/sprints";
export const EDIT_SPRINT_DATA = "/api/sprints";
export const SPRINT_BACKLOG = "/api/issues/product-backlog";

//Comments
export const ADD_COMMENTS = "/api/comments";
export const GET_COMMENTS = "/api/comments/issue";
export const EDIT_COMMENTS = "/api/comments";

//History
export const GET_HISTORY = "/api/issueHistory";

//all
export const GET_ALL = "/api/issues";

//roles
export const ROLES = "/api/roles";
export const PROJECT_ROLES = "/api/roles/project-scope";
export const APPLICATION_ROLES = "/api/roles/application-scope";

//icons
export const DEFAULT_ICONS = "/api/projects/default-icons";

//teams
export const GET_TEAMS = "/api/teams";
export const CREATE_TEAMS = "/api/teams";

//share issues
export const SHARE_ISSUES = "api/issues/share";

// view all issues
export const VIEW_ALL_ISSUES = "api/issues/filter";
export const GET_FILTER_PROJECTS = "api/projects/users";
export const VIEW_ALL_STASTUS = "api/stages";
export const GET_FILTER_ASSIGNEE = "api/users/project";
export const GET_ASSIGNEE_OR_REPORTERS = " updated the endpoint";

export const CREATE_PROJECTS = "/projects/create-projects";


